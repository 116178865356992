import React, { useEffect, useState } from "react";
import { useStep } from "../../../contexts/stepContexts";
import { z } from "zod";

// Define the validation schema
const signUpSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z
    .string()
    .min(7, "Password must be at least 7 characters")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(
      /[0-9]/,
      "Password must contain at least one number or special character"
    ),
});

const SignUp = () => {
  const { changeStep, setUrl } = useStep();

  useEffect(() => {
    setUrl("http://127.0.0.1:5500/escrow/payment");
  }, []);
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = signUpSchema.safeParse(formValues);
    if (result.success) {
      // Form is valid, proceed with form submission logic
      console.log("Form is valid", formValues);
    } else {
      // Form is invalid, update the errors state
      const fieldErrors = result.error.format();
      setErrors({
        email: fieldErrors.email?._errors[0] || "",
        password: fieldErrors.password?._errors[0] || "",
      });
    }
  };

  return (
    <div className="bg-white pt-6 min-lg:h-[900px] lg:w-[800px] sm:w-[500px] md:w-[700px] mx-auto p-4">
      <p className="text-gray-800 mb-2 md:mb-4">
        Kindly visit the official Escrow website to create a new account. Once
        your account is set up, please return to our website to log in and
        continue with the process.
      </p>
      <button
        onClick={() => changeStep("login")}
        className="text-xs font-bold underline underline-offset-4 text-[#3CB95D] border-[#3CB95D]"
      >
        LOGIN TO ESCROW.COM
      </button>
      {/* <div className="divider"></div>
      <div className="space-y-6 w-full flex flex-col items-center pb-10">
        <h1 className="text-2xl font-medium" style={{ color: "#4F5C6E" }}>
          Create an Account to Escrow.com
        </h1>
        <div className="max-w-3xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-5 ">
            <div className="w-full lg:w-[500px] flex flex-col items-center">
              <div className="space-y-2 w-full">
                <h1 className="text-xs text-slate-700 flex tracking-widest">
                  PLEASE ENTER YOUR EMAIL ADDRESS
                  <span style={{ color: "red" }}>* </span>
                </h1>
                <div className="relative w-full">
                  <svg
                    className="absolute left-3 top-1/2 mt-0.5 transform -translate-y-1/2 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17px"
                    height="14px"
                    viewBox="0 0 17 14"
                    version="1.1"
                  >
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="ui-email-alt"
                        fill="currentColor"
                        fillRule="nonzero"
                      >
                        <path d="M1.02,0 C0.476,0 0,0.476 0,1.02 L0,12.512 C0,13.124 0.476,13.6 1.02,13.6 L15.64,13.6 C16.184,13.6 16.66,13.124 16.66,12.58 L16.66,1.02 C16.66,0.476 16.184,0 15.64,0 L1.02,0 Z M15.164,1.564 L15.164,1.972 L8.364,7.684 L1.564,1.972 L1.564,1.564 L15.164,1.564 Z M1.564,12.036 L1.564,4.012 L7.684,9.18 C7.888,9.316 8.092,9.452 8.364,9.452 C8.636,9.452 8.84,9.384 9.044,9.18 L15.164,4.012 L15.164,12.036 L1.564,12.036 Z"></path>
                      </g>
                    </g>
                  </svg>
                  <p className="absolute left-[38px] top-1/2 transform -translate-y-1/2 font-semibold text-gray-400">
                    |
                  </p>
                  <input
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    style={{ border: "1px solid #A4B0B1", borderRadius: "4px" }}
                    type="email"
                    placeholder="Email"
                    className="hover:border-sky-700 input-bordered h-9 w-full pl-12 text-slate-600 placeholder:text-slate-400"
                  />
                </div>
                {errors.email && (
                  <p className="flex text-red-500 text-xs mt-1">
                    {errors.email}
                  </p>
                )}
              </div>
            </div>

            <div className="w-full lg:w-[500px] flex flex-col items-center">
              <div className="space-y-2 w-full">
                <h1 className="text-xs text-slate-700 flex tracking-widest">
                  PLEASE ENTER YOUR PASSWORD
                  <span style={{ color: "red" }}>* </span>
                </h1>
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Layer_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 20.1 24.7"
                    enable-background="new 0 0 20.1 24.7"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 fill-gray-500 w-5 h-5"
                  >
                    <path d="M16.2,9.3V6.2C16.2,2.8,13.4,0,10,0C6.6,0,3.9,2.8,3.9,6.2v3.1H1.5C0.7,9.3,0,10,0,10.8v12.3c0,0.9,0.7,1.5,1.5,1.5h17c0.9,0,1.5-0.7,1.5-1.5V10.8c0-0.9-0.7-1.5-1.5-1.5H16.2z M6.2,6.2C6.2,4,7.9,2.3,10,2.3c2.1,0,3.9,1.7,3.9,3.9v3.1H6.2V6.2z M17.8,22.4H2.3V11.6h15.4V22.4z"></path>
                  </svg>
                  <p className="absolute left-[38px] top-1/2 transform -translate-y-1/2 font-semibold text-gray-400">
                    |
                  </p>
                  <input
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                    style={{ border: "1px solid #A4B0B1", borderRadius: "4px" }}
                    type="password"
                    placeholder="Password"
                    className="hover:border-sky-700 input-bordered h-9 w-full pl-12 text-slate-600 placeholder:text-slate-400"
                  />
                </div>
                {errors.password && (
                  <p className="flex text-red-500 text-xs mt-1">
                    {errors.password}
                  </p>
                )}
              </div>
            </div>

            <div className="" style={{ color: "#4F5759" }}>
              <div>
                <p className="text-gray-500 flex">Strong passwords have</p>
                <ul className="">
                  <PasswordFeature text="At least 7 characters" />
                  <PasswordFeature text="At least one UPPER case and one lower case character" />
                  <PasswordFeature text="At least one number or special character" />
                </ul>
              </div>
            </div>

            <div className="w-full lg:w-[500px] flex flex-col items-center">
              <button
                type="submit"
                className="text-white bg-[#3CB95D] w-full rounded-md py-2"
              >
                Sign Up
              </button>
            </div>
          </form>

          <div className="space-y-5">
            <div className="text-gray-500">-OR-</div>

            <div className="w-full flex flex-col items-center">
              <div className="relative w-full text-center">
                <button
                  onClick={() => changeStep("login")}
                  className="text-xs font-bold underline underline-offset-4 text-[#3CB95D] border-[#3CB95D]"
                >
                  LOGIN TO ESCROW.COM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SignUp;

const PasswordFeature = ({ text }) => {
  return (
    <li className="flex items-center gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        class="icon injected-svg signupForm-suggestion-icon"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        data-src="../../../../build/images/global/icons/tick.svg"
        className="fill-gray-200 font-thin w-3 h-3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.7,4l-15,15c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.9,0.9-2.4,0.9-3.2,0      l-5.5-4.7c-0.9-0.9-0.9-2.4,0-3.2c0.9-0.9,2.4-0.9,3.2,0l3.7,3.2L21.4,0.7c0.9-0.9,2.4-0.9,3.3,0C25.6,1.6,25.6,3.1,24.7,4z"
        ></path>
      </svg>
      <p className="text-sm text-gray-500"> {text}</p>
    </li>
  );
};
