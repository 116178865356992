import React from "react";
import ReportDesc from "../ReportScam/ReportDesc";

export default function UpdateNoticeCard({
  title,
  description,
  image1,
  image2,
  image3,
  image4,
  image5,
  date,
}) {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <div class="container px-2  mx-auto ">
      <div class="my-4 divide-y-2 divide-[#0d957c] shadow-2xl">
        <div class="px-[calc(1%)] -my-1 flex justify-between">
          <span class="mt-1 text-yellow-400 bg-yellow-50 font-semibold   w-[150px] rounded hover:animate-bounce text-left text-md px-4">
            {new Date(date).toLocaleDateString("en-GB")}
          </span>
        </div>
        <div
          class="py-8 flex flex-wrap md:flex-nowrap border border-indigo-200 backdrop-blur-sm rounded   px-5
      "
        >
          <div className=" block items-center mx-auto ">
            <div className="block md:flex items-center md:justify-between  ">
              {image1 && (
                <img
                  src={image1}
                  alt={title}
                  className=" lg:w-[250px] md:w-1/2 w-[250px] mx-auto rounded-2xl p-1"
                />
              )}
              {image2 && (
                <img
                  src={image2}
                  alt={title}
                  className=" lg:w-[250px] md:w-1/2 w-[250px] mx-auto rounded-2xl p-1"
                />
              )}
              {image3 && (
                <img
                  src={image3}
                  alt={title}
                  className=" lg:w-[250px] md:w-1/2 w-[250px] mx-auto rounded-2xl p-1"
                />
              )}
              {image4 && (
                <img
                  src={image4}
                  alt={title}
                  className=" lg:w-[250px] md:w-1/2 w-[250px] mx-auto rounded-2xl p-1"
                />
              )}
              {image5 && (
                <img
                  src={image5}
                  alt={title}
                  className=" lg:w-[250px] md:w-1/2 w-[250px] mx-auto rounded-2xl p-1"
                />
              )}
            </div>
            <div class="md:flex-grow m-[calc(1%)]">
              <h2 class="lg:text-2xl text-xl font-medium text-yellow-400 title-font mb-2">
                {title}
              </h2>
              <p class="leading-relaxed">
                <ReportDesc description={description} />
              </p>
            </div>
          </div>
        </div>
        {/* More three Images if Exist */}
      </div>

      {/* card end */}
    </div>
  );
}
