import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App.js";
import "./styles.css";
import { StepProvider } from "./contexts/stepContexts.js";
import DisableInspect from "./components/DisableInspect.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StepProvider>
        <DisableInspect />
        <App />
      </StepProvider>
    </BrowserRouter>
  </React.StrictMode>
);
