const baseUrl = "https://api.hydraflasher.com/";
// const baseUrl = "http://localhost:3001/";
const coinbase_invoiceUrl = "https://commerce.coinbase.com/invoices/";

async function getPublicIP() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
  }
}
// const invoiceRoute = baseUrl + "user/productinvoice";
const invoiceRoute = baseUrl + "invoices";
const isCouponValid = baseUrl + "invoices/isCouponValid";
const allTransactions = baseUrl + "all-tnx";
const loginRoute = baseUrl + "user/login";
const getUser = baseUrl + "user";
const registerRoute = baseUrl + "user/register";
const BuyProductUrl = baseUrl + "invoices/get_address";
const EscrowBlockonomics = baseUrl + "invoices/escrow/address/generate";
const UpdateInvoiceUrl = baseUrl + "invoices";
const NewsUrl = baseUrl + "news";

const NoticeUrl = baseUrl + "notice";

const ScamReportUrl = baseUrl + "scam_report";

const ScamReportPostUrl = baseUrl + "scam_report/create-scam-report";
const RefundReq = baseUrl + "refund-request";
const RefundReqGet = baseUrl + "refund-request/get";
export {
  invoiceRoute,
  coinbase_invoiceUrl,
  getPublicIP,
  loginRoute,
  registerRoute,
  BuyProductUrl,
  RefundReq,
  RefundReqGet,
  NewsUrl,
  NoticeUrl,
  ScamReportUrl,
  baseUrl,
  ScamReportPostUrl,
  UpdateInvoiceUrl,
  allTransactions,
  EscrowBlockonomics,
  getUser,
  isCouponValid,
};
