import React from "react";
import { NewsUrl } from "../../utils/Routes";
import NewsCard from "../News/NewsCard";

export default function News() {
  const [newses, setNews] = React.useState([]);
  const getNewsData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    fetch(NewsUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setNews(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getNewsData();
  }, []);
  return (
    <section class="   text-gray-300 ">
      <div class="container px-5 py-24 mx-auto">
        <div className="py-10">
          <h2 className="text-3xl  text-center font-bold w-full hover:underline">
            Crypto News :
          </h2>
          <div>
            <h6 className="text-lg font-semibold mx-6 min-w-[80%] my-[calc(2%)]">
              Stay up-to-date on the latest happenings, trends, and emerging
              technologies in the dynamic world of cryptocurrencies with our
              dedicated crypto news website. Make informed investment decisions
              by accessing timely updates and developments in the market.
            </h6>
          </div>{" "}
        </div>
        <div class="-my-8 divide-y-2 divide-gray-100">
          {newses.length > 0 ? (
            newses.map((news) => <NewsCard key={news?._id} news={news} />)
          ) : (
            <h2 className="text-center animate-pulse text-lg h-[calc(50%)] my-20 font-mono font-semibold">
              News Are Loading . . .{" "}
            </h2>
          )}
        </div>
      </div>
    </section>
  );
}

/*   */
