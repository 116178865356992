import React, { useState, useEffect } from "react";
import DraggableWindow from "../DraggableWindow";
import Login from "./Login";
import { useStep } from "../../../contexts/stepContexts";
import SignUp from "./SignUp";
import PaymentOptions from "./PaymentOptions";
import MethodConfirmation from "./MethodConfirmation";
import Success from "./Success";

const EscrowPayment = ({ openWindow, showWindow, closeWindow, details }) => {
  const { step, changeStep } = useStep();

  const renderComponent = () => {
    switch (step) {
      case "login":
        return <Login details={details} />;
      case "signup":
        return <SignUp />;
      case "payment":
        return <PaymentOptions details={details} />;
      case "method-confirmed":
        return <MethodConfirmation details={details} />;
      case "success":
        return <Success details={details} />;
      default:
        return <Login />;
    }
  };

  return (
    <div className="">
      {showWindow && (
        <DraggableWindow onClose={closeWindow}>
          <div>{renderComponent()}</div>
        </DraggableWindow>
      )}
    </div>
  );
};

export default EscrowPayment;
