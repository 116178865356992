import React, { useEffect, useRef, useState } from "react";
import {
  RefundReq,
  RefundReqGet,
  getUser,
  invoiceRoute,
} from "../../utils/Routes";
import ModalPOST from "../shared/ModalPOST";
import Logo from "../../../src/assets/images/logo192.png";
import Modal from "../price/Modal";
import QRCode from "react-qr-code";
import axios from "axios";
import { BuyProductUrl, UpdateInvoiceUrl } from "../../utils/Routes";
import moment from "moment";
import { Link } from "react-router-dom";
import LicenseKeyComponent from "../Dashboard/LicenseKeyComponent";
import DraggableWindow from "../price/DraggableWindow";
import PackageDescription from "../price/PackageDescription";
import Stepper from "../price/Escrow/Stepper";
import CopyComponent from "../Dashboard/CopyComponent";
import { io } from "socket.io-client";

const Dashboard = () => {
  const [userInfo, setUserInfo] = React.useState([]);
  const [Invoices, setInvoices] = React.useState([]);
  const [refundReq, setRefundReq] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [currentInvoice, setCurrentInvoice] = React.useState({});
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState("Pay");

  const handleOpenPaymentModel = (invoice) => {
    setCurrentInvoice(invoice);
    if (invoice.payment_type === "Escrow") {
      openWindow(invoice?.address);
    } else {
      openModal(invoice?.address);
    }
  };

  const getUserdata = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        x_api_key: "JNSDJ_SD7245847572457_jdasfjdkf_sdasdsad",
      },
    };
    let userFromLocal = JSON.parse(localStorage.getItem("user"));

    const user = fetch(
      getUser + "?email=" + userFromLocal.email,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setUserInfo(data);
        console.log("live user data", data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (user) {
    } else {
      window.location.href = "/login";
    }
  };
  const getInvoices = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
      fetch(invoiceRoute + "?email=" + user.email, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log("invoices data", data);
          setInvoices(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const logOut = () => {
    localStorage.removeItem("user");
    window.location.href = "/login";
    // navigate("/login");
  };
  const RefundLists = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    fetch(RefundReqGet, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setRefundReq(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getUserdata();
    getInvoices();
    // RefundLists();
  }, []);

  let [clicked, setClicked] = React.useState(false);
  let [clickedId, setClickedId] = React.useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (address) => {
    setIsModalOpen(true);

    if (address) {
      const ws = new WebSocket(`wss://www.blockonomics.co/payment/${address}`);

      ws.onopen = function () {
        console.log("");
        // console.log("WebSocket connection established.");
      };

      ws.onmessage = async function (event) {
        const txs = JSON.parse(event.data);
        // console.log("Received new transactions:", txs);

        if (txs) {
          let status;
          if (txs.status == -1) {
            status = "PAYMENT_ERROR";
          } else if (txs.status == 0) {
            status = "Unconfirmed";
          } else if (txs.status == 1) {
            status = "Partially Confirmed";
          } else if (txs.status == 2) {
            status = "Confirmed";
          }

          try {
            await axios.patch(UpdateInvoiceUrl + `/${address}`, {
              trx_id: txs.txid,
              status,
              paid_amount: txs.value,
              address: address,
            });

            setStep("Success");
            // window.location.reload();
          } catch (error) {
            console.error(error);
          }
        }

        // Handle the received transactions here, you can update state or perform any other action
      };

      ws.onclose = function () {
        console.log("");
        // console.log("WebSocket connection closed.");
      };

      // Clean up on component unmount
      return () => {
        ws.close();
      };
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [showWindow, setShowWindow] = useState(false);

  const openWindow = (address) => {
    setShowWindow(true);

    if (address) {
      const socket = io(`https://api.hydraflasher.com/payment/${address}`);

      // client-side
      socket.on("connect", () => {
        console.log("Connection Established");
      });

      socket.on("message", async (txs) => {
        if (txs) {
          let status;
          if (txs.status === -1) {
            status = "PAYMENT_ERROR";
          } else if (txs.status === 0) {
            status = "Unconfirmed";
          } else if (txs.status === 1) {
            status = "Partially Confirmed";
          } else if (txs.status === 2) {
            status = "Confirmed";
          }

          try {
            await axios.patch(UpdateInvoiceUrl + `/${address}`, {
              trx_id: txs.txid,
              status,
              paid_amount: txs.value,
              address: address,
            });

            // changeStep("success");
          } catch (error) {
            console.error(error);
          }
        }
      });

      socket.on("disconnect", () => {
        console.log(socket.id);
      });
    }
  };
  const closeWindow = () => setShowWindow(false);

  return userInfo.isSuspended ? (
    <div className="py-14">
      <div class="flex w-full mx-auto max-w-5xl overflow-hidden bg-white rounded-lg shadow-md ">
        <div class="flex items-center justify-center w-12 bg-red-500">
          <svg
            class="w-6 h-6 text-white fill-current"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
          </svg>
        </div>

        <div class="px-4 py-5 -mx-3">
          <div class="mx-3">
            <p class="font-semibold text-red-500 mb-2 md:mb-4">
              Multiple accounts detected!
            </p>
            <div className="space-y-1 md:space-y-2">
              <p class="text-sm text-gray-600 dark:text-gray-200">
                We have identified the presence of multiple accounts. This
                activity is against our policy and may lead to suspension or
                further action if not resolved.
              </p>
              <p class="text-sm text-gray-600 dark:text-gray-200">
                Please review your account usage and ensure compliance with our
                terms of service. If you believe this detection is an error,
                contact our support team immediately for assistance.
              </p>
              <p class="text-sm text-gray-600 dark:text-gray-200">
                Thank you for your prompt attention to this matter.{" "}
              </p>
            </div>
          </div>
          <button
            className="p-2 bg-[#ed5656] border rounded mt-3 ml-3 px-4 text-white"
            onClick={() => {
              logOut();
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="container mx-auto px-5 flex justify-between  flex-col lg:flex-row">
      <div className="grid grid-cols-1 gap-4 w-full mt-6 mb-6">
        <div className="p-5 bg-gray-800 text-white border  shadow border-gray-600">
          <h1 className="text-[30px] text-[bold]">
            {userInfo.fname + " " + userInfo.lname}
          </h1>
          <h1 className="text-[20px] text-[bold]">Email: {userInfo.email}</h1>
          <div className="flex gap-4">
            <button
              className="p-2 bg-[#ed5656] border rounded mt-3"
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </button>
            <Link to="/all-transactions">
              <button
                class="block mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
              >
                All Transactions
              </button>
            </Link>
          </div>

          <button
            onClick={() => {
              setShowModal(true);
            }}
            class="block mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            Review now
          </button>

          <div
            class={`${
              showModal ? "flex" : "hidden"
            } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
          >
            <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
              <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Make a review
                  </h3>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>

                <div class="p-6 space-y-6">
                  <div
                    class={`${
                      showAlert ? "block" : "hidden"
                    } bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative`}
                    role="alert"
                  >
                    <strong class="font-bold">You can't review!. </strong>
                    <span class="block sm:inline">
                      You didn't bought any product now.
                    </span>
                  </div>

                  <form action="#" class="space-y-8">
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Your email
                      </label>
                      <input
                        type="email"
                        id="email"
                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        placeholder="name@flowbite.com"
                        required
                      />
                    </div>
                    <div class="sm:col-span-2">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        Your message
                      </label>
                      <textarea
                        id="message"
                        rows="6"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Leave a comment..."
                      ></textarea>
                    </div>
                    <button
                      onClick={() => {
                        setShowAlert(true);
                      }}
                      data-modal-toggle="defaultModal"
                      type="button"
                      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 text-white border  shadow border-gray-600">
          <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="py-3 px-6">
                    Invoice Code
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Product name
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Price
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Paid Amount
                  </th>
                  {/* <th scope="col" class="py-3 px-6">
              Paid
            </th> */}
                  <th scope="col" class="py-3 px-6">
                    Refund
                  </th>
                  <th scope="col" class="py-3 px-6">
                    License status
                  </th>
                  <th scope="col" class="py-3 px-6">
                    License key
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Date
                  </th>
                  <th scope="col" class="py-3 px-6">
                    Payment Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {Invoices.length === 0 && "NO INVOICES"}
                {Invoices.map((element, i) => {
                  return (
                    <>
                      <tr
                        key={i}
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      >
                        <th
                          scope="row"
                          class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {element?.invoice_code}
                        </th>
                        <th
                          scope="row"
                          class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {element?.product_name}
                        </th>
                        <td class="py-4 px-6">${element?.price}</td>
                        <td class="py-4 px-6">${element?.paid_amount}</td>
                        <td class=" p-4">
                          {element?.status === "Confirmed" ? (
                            <button
                              onClick={() => {
                                setClicked(true);
                                setClickedId(element?._id);
                              }}
                              className={`btn-success	 px-2 py-1 rounded-full`}
                            >
                              Refund
                            </button>
                          ) : (
                            <h2>{element?.status}</h2>
                          )}
                          {clicked && clickedId === element?._id && (
                            <ModalPOST
                              key={i}
                              email={userInfo.email}
                              product_id={element?._id}
                              invoice_id={element?.invoice_code}
                              product_status={element?.status}
                              // product_status={element?.product_status}
                            />
                          )}
                        </td>
                        <td class=" p-4">
                          {element?.product_status == 1 ? (
                            <h1>Active</h1>
                          ) : element?.product_status == 2 ? (
                            <h1>Not paid</h1>
                          ) : (
                            <h1>Deactive</h1>
                          )}
                        </td>

                        <td className="px-6 p-4">
                          <LicenseKeyComponent
                            licenceKey={element?.licence_key}
                          />
                        </td>

                        <th class="py-4 px-6">
                          {moment(element?.issued_date).format("lll")}
                        </th>
                        <td class="py-4 px-6">
                          {element?.isSystemGenerated ? (
                            <></>
                          ) : element?.status === "UNPAID" ? (
                            <button
                              onClick={() => handleOpenPaymentModel(element)}
                              class="block mt-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              type="button"
                            >
                              Pay Now
                            </button>
                          ) : (
                            element?.status
                          )}
                          {/* {genrate_invoice_Status(
                      element.product_status,
                      coinbase_invoiceUrl + element.coinbase_invoice_code
                    )} */}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="">
        {showWindow && (
          <DraggableWindow onClose={closeWindow}>
            <div className="pb-10 pt-8 mb-10 overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between">
                  <p></p>
                  <p className="text-end" style={{ color: "#3CB95D" }}>
                    <span className="font-semibold">Total</span> $
                    {currentInvoice?.price} USD
                  </p>
                </div>

                <div className="flex mt-8">
                  <div className="flex-1">
                    <div className="flex flex-col justify-start space-y-2 text-gray-900">
                      <p className="font-semibold flex">
                        {currentInvoice?.product_name}
                      </p>
                      <div className="flex text-left">
                        <p className="tracking-tighter">
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Harum odio quidem sequi voluptatem dicta,
                          praesentium officiis tenetur. Voluptatum a quibusdam
                          esse, beatae, in ab delectus non optio hic doloremque
                          placeat debitis corporis! Explicabo libero deserunt
                          velit harum ipsum! Expedita commodi laudantium alias
                          omnis inventore ducimus harum perferendis, nam
                          necessitatibus accusantium.
                        </p>
                      </div>
                      <p className="flex">Inspection period: 3 days</p>
                      <p className="flex">Escrow fee payer: 3 days</p>
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 text-gray-900 space-y-2 px-10 flex flex-col justify-end">
                    <p className="flex">${currentInvoice?.price} USD</p>
                    <p>
                      <span className="font-semibold">Escrow Fee</span> $32.00
                      USD
                    </p>
                  </div>
                </div>
              </div>

              <hr className="my-14" />

              {/* <Stepper currentStep={2} /> */}

              {step === "Pay" ? (
                <div className="mx-auto lg:w-[800px] p-6">
                  <h1 className="font-bold text-center mb-6 text-gray-700 flex">
                    Paying through {currentInvoice?.payment_type}
                  </h1>

                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mb-3 md:mb-6">
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 150,
                          width: "100%",
                        }}
                        className=""
                      >
                        {currentInvoice?.address && (
                          <div
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                          >
                            <QRCode
                              className="border-2 border-[#3CB95D] p-1 md:p-2"
                              size={216}
                              value={currentInvoice?.address}
                              fgColor="#3CB95D" // Green color
                              viewBox="0 0 256 256"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 md:gap-4 items-center mt-2 md:mt-4 text-center tracking-wide font-mono space-y-1 text-gray-800">
                      <CopyComponent text={currentInvoice?.address} />
                      <div className="flex">
                        Amount(BTC)-
                        <CopyComponent text={currentInvoice?.price_in_btc} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mx-auto lg:w-[800px] p-6">
                  <div class="">
                    <div class="bg-white p-6  md:mx-auto">
                      <svg
                        viewBox="0 0 24 24"
                        class="text-green-600 w-16 h-16 mx-auto my-6"
                      >
                        <path
                          fill="currentColor"
                          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                        ></path>
                      </svg>
                      <div class="text-center">
                        <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
                          Payment Done!
                        </h3>
                        <p class="text-gray-600 my-2">
                          Thank you for completing your secure online payment.
                        </p>
                        <p className="text-gray-600"> Have a great day! </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DraggableWindow>
        )}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="bg-[#26303C] px-4 pt-5 pb-4 sm:p-6 sm:pb-4 z-[999]">
            <div className="sm:flex justify-between sm:items-start">
              <div className="flex justify-between">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <img src={Logo} alt="Logo" className="" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-white"
                    id="modal-headline"
                  >
                    {currentInvoice?.product_name}
                  </h3>
                  {/* <div className="mt-2">
                            <p className="text-sm text-gray-200">
                              {details?.header}
                            </p>
                          </div> */}
                </div>
              </div>
              <div>
                <p className="text-gray-900 bg-gray-200 px-4 py-1 rounded-md">
                  ${currentInvoice?.price}
                </p>
              </div>
            </div>
            <div className="mt-3 md:mt-6 text-center tracking-wide font-mono space-y-1 text-gray-200">
              <p className="">You can simply send the BTC to this address:</p>
              <p> {currentInvoice?.address}</p>
              <div className="flex gap-2 justify-center pt-2">
                <p className="">Amount:</p>
                <p>{currentInvoice?.price_in_btc} BTC</p>
              </div>
            </div>
            <div className="mt-3 md:mt-6">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 150,
                  width: "100%",
                }}
                className="border-2"
              >
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value={currentInvoice?.address}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Dashboard;
