import styles from "./floating.module.css";
import { ImTelegram } from "react-icons/im";
import { BsTelegram, BsTwitter, BsYoutube, BsChat } from "react-icons/bs";
import data from "../../data/youtubeTelegram";

export default function FloatingIcon() {
  return (
    <div className="flex fixed right-6 bottom-10 flex-col gap-4 items-end">
      <a
        href={data.twitter}
        target="_blank"
        className={styles.float}
        id={styles.twitter}
      >
        <BsTwitter size={"1.5rem"} />
      </a>
      <a
        href={data.telegramchnl}
        target="_blank"
        className={styles.float}
        id={styles.telegramchnl}
      >
        <BsTelegram size={"1.5rem"} />
      </a>
      <a
        href={data.telegram}
        target="_blank"
        className={styles.float}
        id={styles.telegram}
      >
        Contact us <ImTelegram size={"1.5rem"} className={"ml-4"} />
      </a>
      <a
        href={"/Coming_Soon"}
        target="_blank"
        className={styles.float}
        id={styles.telegram}
      >
        Live Chat <BsChat size={"1.5rem"} className={"ml-4"} />
      </a>
    </div>
  );
}
