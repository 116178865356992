import React from "react";
import { Link } from "react-router-dom";
import { Review } from "./Review"

export const Reviews = () => {

	const [reviewData, setreviewData] = React.useState([]);
    const getreviewssData = () =>{
        const requestOptions = {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
      			"Access-Control-Allow-Origin": "*",
			},
		};
        fetch("/reviews-data.json", requestOptions)
			.then(response => response.json())
			.then(data => {
				// console.log(data);
				let arr = [data[0], data[1], data[2]]
                setreviewData(arr);
			})
			.catch(err => {
				console.log(err);
			});
    }
    React.useEffect(()=>{
        getreviewssData();
    }, []);
	return (
		<div className="container mx-auto px-5 flex flex-col items-center mb-5">
			<p className="text-3xl lg:w-1/2 text-center font-bold">
				CUSTOMER’S FEEDBACK
			</p>
			<div className="flex justify-between gap-6 mt-16 flex-col lg:flex-row">
			{
                reviewData.map((item, i)=>{
                    return (
                        <Review 
						key={i}
                            review={item.review}
                            username={item.username}
                            profession={item.profession}
                            rating={item.rating}
                        />
                    )
                })
            }
			</div>
			<Link
			to="/reviews"
			 className="mt-5 bg-base-100 p-3 text-[black] rounded border-primary border-[1px]">See More</Link>
		</div>
	)
}
