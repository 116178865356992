import React, { useEffect, useState } from "react";
import ReportModalPOST from "../shared/ReportModalPost";
import ReportScamCard from "../../ReportScam/ReportScamCard";
import { ScamReportUrl } from "../../utils/Routes";

export default function Report() {
  const [isClicked, setIsClicked] = useState(false);

  const [userInfo, setUserInfo] = useState([]);
  if (userInfo == null) {
    window.location.href = "/login";
  }

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem("user")));
  }, []);

  const [datas, setScamReport] = React.useState([]);
  const getScamReport = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    fetch(ScamReportUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setScamReport(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getScamReport();
  }, []);

  return (
    <section className="container mx-auto px-5 flex flex-col items-center my-16">
      {isClicked && <ReportModalPOST userInfo={userInfo} />}
      {/* Report Scam Part 1 - Form */}
      <div id="Apply">
        <h2 className="text-3xl  text-center font-bold w-full hover:underline">
          Report Scam :
        </h2>
        <div>
          <h6 className="text-lg font-semibold min-w-[80%] my-[calc(2%)]">
            Report scams and protect others from falling victim. Our platform
            provides a safe space for individuals to report suspicious activity
            and help prevent scams. Join us in creating a secure community.
          </h6>
        </div>{" "}
        <div className="flex justify-center">
          <a
            onClick={() => setIsClicked(true)}
            className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
          >
            Report Scam
          </a>
        </div>
      </div>
      {/* Report Scam Part 2 - Past Scam Reports - Blog */}

      <div class="container px-5 py-16 mx-auto ">
        <h2 className="text-3xl  text-center font-bold w-full hover:underline">
          Recent Scam Reports:
        </h2>
        {/* Displaying Recent Reports in Card */}

        {datas.length > 0 ? (
          datas.map((x) => (
            <ReportScamCard
              key={x._id} // add a unique key prop to avoid console errors
              title={x?.title}
              description={x?.desc}
              date={x?.date} // corrected from x?.description
              images={x?.images}
              userId={x?.userId}
              userMail={x?.userMail}
              image1={x?.image1}
              image2={x?.image2}
              image3={x?.image3}
              image4={x?.image4}
              isUpdated={x?.isUpdated}
            />
          ))
        ) : (
          <h2 className="text-center animate-pulse text-lg h-[calc(50%)] my-20 font-mono font-semibold">
            Reports Are Loading . . .{" "}
          </h2>
        )}
        {/* card end */}
      </div>
    </section>
  );
}
