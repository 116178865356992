import React from 'react'
import './Featured.css'


export const Featured = () => {



  return (
    <div className=' container mx-auto px-6 '>

      <div className=' grid grid-row lg:grid-cols-4 gap-x-4 '>

        <div className='featured h-auto lg:max-x-lg md:max-w-md sm:max-w-sm'>
          <p className='pt-3 px-5 text-[40px] font-[600] text-[#545767]  '>01</p>
          <p className='px-5 mt-2 text-[20px]  font-extrabold text-white  '>Safe and Secure</p>
          <p className='px-5 mt-3 pb-5 font-mono '> Hydra Flasher is Using Private Script For Flashing. No Third Party Software or Script is Using For Flash. </p>
        </div>

        <div className='featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  '>
          <p className='number pt-3 px-5 text-[40px] font-[600] text-[#545767]  '>02</p>
          <p className='px-5 mt-2 text-[20px]  font-extrabold text-white  '>Block Tracking</p>
          <p className='px-5 mt-3 pb-5'> Our Flasher Do Bypass Miner Address For Sending Flash Dollar. It Will Help You To Be Anonymous.  </p>
        </div>


        <div className='featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  '>
          <p className='number pt-3 px-5 text-[40px] font-[600] text-[#545767]  '>03</p>
          <p className='px-5 mt-2 text-[20px]  font-extrabold text-white  '>Instant Flash</p>
          <p className='px-5 mt-3 pb-5'> You Can Flash Your Wallet Instantly. No Time Killing. And Flash Lasts For 21 Days. </p>
        </div>

        <div className='featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  '>
          <p className='number pt-3 px-5 text-[40px] font-[600] text-[#545767]  '>04</p>
          <p className='px-5 mt-2 text-[20px]  font-extrabold text-white  '>Sell and Trade Facility</p>
          <p className='px-5 mt-3 pb-5'> Our Flash Dollar Is Transferable To Almost All Wallet, Including Binance. You Can Trade The Flash Funds And Turn It Into Real Dollar. </p>
        </div>

      </div>
    </div>
  )
}


