import React from 'react'

const VideoCard = ({src, title}) => {
  return (
    <div className="flex flex-col  bg-accent/70 backdrop-blur-md rounded-2xl">
			<iframe style={{outline: "none"}} src={src} width="100%" height="200" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title={title}></iframe>
		</div>
  )
}

export default VideoCard