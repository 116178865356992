import React from "react";

const Terms = () => {
  return (
    <div className="max-h-[200px] overflow-auto border-2 p-3 text-gray-500">
      <p className="terms-text">
        As a condition of use, you promise not to use the HydraFlasher Services
        for any purpose that is unlawful or prohibited by these Terms. By way of
        example. and not as a limitatian. you agree not to use the Services:
        <br />
        <br />
        1. To abuse. harass. threaten. impersonate or intimidate any person.
        <br />
        2. To post or transmit. or cause to be pasted or transmitted. any
        Content that is libelous. defamatory, obscene, pornographic, abusive.
        offensive, profane. or that infringes any copyrignt or otner rigm or any
        person.
        <br />
        3. To communicate with representatives or other users in an abusive or
        offensive manner.
        <br />
        4. For any purpose including posting or viewing Content that is not
        permitted under the laws Of the jurisdiction where you use the Services.
        <br />
        5. To post or transmit or-cause to be posted or transmitted, any
        Cammunication designed or intenaed to obtain password. account. or
        private informallon from any user.
        <br />
        6. To create or transmit unwanted 'spam' to any person or any URL.
        <br />
        7. To create multiple accounts for the purpose of voting for or against
        users visual Content.
        <br />
        8. Using our software in multiple device will causes your license
        restriction.
        <br />
        9. Due to blank miner, sometimes flash may not success. Then Change your
        Wallet and flash a new one. Blank flash will not count in daily limit of
        subscription.
        <br />
        *Paid License will available in our Shop section. Our old Sellix Shop is
        closed* .
      </p>
    </div>
  );
};

export default Terms;
