import React, { useEffect, useRef } from "react";

const CloudflareCaptcha = ({ onCaptchaChange }) => {
  const captchaRef = useRef(null);

  useEffect(() => {
    const handleCaptchaChange = () => {
      if (window.turnstile && captchaRef.current) {
        const token = window.turnstile.getResponse();
        onCaptchaChange(token);
      }
    };

    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.async = true;
    script.onload = () => {
      window.turnstile.render(captchaRef.current, {
        sitekey: "0x4AAAAAAAcZcgVwwFeY4Uxy",
        callback: handleCaptchaChange,
      });
    };
    document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return <div ref={captchaRef} className="w-full flex justify-center"></div>;
};

export default CloudflareCaptcha;
