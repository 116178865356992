import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BuyProductUrl,
  UpdateInvoiceUrl,
  isCouponValid,
} from "../../utils/Routes";
import axios from "axios";
import Logo from "../../../src/assets/images/logo192.png";
import Modal from "./Modal";
import QRCode from "react-qr-code";
import Terms from "./Terms";
import ChooseModal from "./ChooseModal";
import Blockonomics from "../../../src/assets/images/blockonomics_black_logo.svg";
import Escrow from "../../../src/assets/images/escrow.svg";
import DraggableWindow from "./DraggableWindow";
import Text from "./PackageDescription";
import PackageDescription from "./PackageDescription";
import EscrowPayment from "./Escrow/EscrowPayment";
import { useStep } from "../../contexts/stepContexts";

export const PriceCard = ({ details }) => {
  // console.log("price details", details);
  const { priceAfterVoucher, setPriceAfterVoucher } = useStep();

  const api_key = "aSJXXLJcwFSYMrjCXrZw3ZzbEUMksfUiGudHToY3uwA";

  const [address, setAddress] = useState(null);
  const [btcRate, setBtcRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updatedPackagePrice, setUpdatedPackagePrice] = useState(0);

  useEffect(() => {
    if (address) {
      const ws = new WebSocket(`wss://www.blockonomics.co/payment/${address}`);

      ws.onopen = function () {
        console.log("");
        // console.log("WebSocket connection established.");
      };

      ws.onmessage = async function (event) {
        const txs = JSON.parse(event.data);
        // console.log("Received new transactions:", txs, event);

        if (txs) {
          let status;
          if (txs.status === -1) {
            status = "PAYMENT_ERROR";
          } else if (txs.status === 0) {
            status = "Unconfirmed";
          } else if (txs.status === 1) {
            status = "Partially Confirmed";
          } else if (txs.status === 2) {
            status = "Confirmed";
          }

          try {
            await axios.patch(UpdateInvoiceUrl + `/${address}`, {
              trx_id: txs.txid,
              status,
              paid_amount: txs.value,
              address: address,
            });

            window.location.reload();
          } catch (error) {
            console.error(error);
          }
        }

        // Handle the received transactions here, you can update state or perform any other action
      };

      ws.onclose = function () {
        console.log("");
        // console.log("WebSocket connection closed.");
      };

      // Clean up on component unmount
      return () => {
        ws.close();
      };
    }
  }, [address]);

  const [showCouponInput, setShowCouponInput] = useState(true);

  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [termsAccepted, setTermAccepted] = React.useState(false);
  // const [userInfo, setUserInfo] = React.useState([]);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponCodeError, setCouponCodeError] = React.useState("");

  const handleUserLogging = () => {
    let user = localStorage.getItem("user");

    if (user !== null) {
      setShowModal(true);
    } else {
      navigate("/login");
    }
  };

  const handlePayment = async () => {
    let user = localStorage.getItem("user");

    if (user != null) {
      let userInfo = JSON.parse(user);

      try {
        const response = await axios.post(BuyProductUrl, {
          price: details?.price,
          coupon: couponCode,
          product_name: details?.title,
          email: userInfo.email,
        });

        setAddress(response?.data?.newAddressData?.address);
        setBtcRate(response?.data?.price_in_btc);
        setUpdatedPackagePrice(response?.data?.price);
        closeModal_package();
        openModal();
      } catch (error) {
        console.error(error);
        // Handle payment errors
      }
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen_package, setIsModalOpen_package] = useState(false);

  const openModal_package = () => {
    setIsModalOpen_package(true);
  };

  const closeModal_package = () => {
    setIsModalOpen_package(false);
  };

  const [checkedValue, setCheckedValue] = useState("");

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCheckedValue(event.target.value);
    } else {
      setCheckedValue("");
    }
  };

  const handleContinue = async () => {
    setLoading(true);

    if (checkedValue === "blockonomics") {
      let user = localStorage.getItem("user");

      if (user != null) {
        let userInfo = JSON.parse(user);

        try {
          const response = await axios.post(BuyProductUrl, {
            price: priceAfterVoucher,
            coupon: couponCode,
            product_name: details?.title,
            email: userInfo.email,
          });

          setAddress(response?.data?.newAddressData?.address);
          setBtcRate(response?.data?.price_in_btc);
          setUpdatedPackagePrice(response?.data?.price);
          closeModal_package();
          openModal();
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
          // Handle payment errors
        }
      }
    } else if (checkedValue === "escrow") {
      closeModal_package();
      openWindow();
    }
  };

  const handleAccept = async () => {
    if (couponCode) {
      try {
        const response = await axios.post(isCouponValid, {
          coupon: couponCode,
          price: details?.price,
        });

        if (response.data.isCouponValid) {
          setPriceAfterVoucher(response.data.price);
          setCouponCodeError("");
          openModal_package();
          setShowModal(false);
        } else {
          setCouponCodeError("Voucher is not valid!");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setPriceAfterVoucher(details?.price);
      openModal_package();
      setShowModal(false);
    }
  };

  const [showWindow, setShowWindow] = useState(false);

  const openWindow = () => setShowWindow(true);
  const closeWindow = () => setShowWindow(false);

  return (
    <div>
      <div
        class={`${
          showModal ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Terms and conditions
              </h3>
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <Terms />

              {showCouponInput && (
                <input
                  className="rounded-md px-6 py-2 text-red-400 font-bold border border-gray-400"
                  id="coupon_code"
                  type="text"
                  onChange={(e) => {
                    setCouponCode(e.target.value);
                  }}
                  placeholder="Coupon Code - Optional"
                />
              )}
              {/* {couponCode && (
                <p className="text-blue-600 text-sm font-semibold">
                  * Voucher will apply after selecting payment method.
                </p>
              )} */}
              {couponCodeError && (
                <p className="text-red-600 text-sm font-semibold">
                  {couponCodeError}
                </p>
              )}
              <div class="flex items-center mb-4">
                <input
                  onChange={(e) => {
                    setTermAccepted(e.target.checked);
                  }}
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="default-checkbox"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  I Agree to terms and conditions.
                </label>
                <br />
              </div>

              <button
                onClick={() => {
                  handleAccept();
                }}
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                disabled={termsAccepted ? false : true}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white">
        <h3 class="mb-4 text-2xl font-semibold">{details?.title}</h3>
        <p class="font-light sm:text-lg text-gray-400">{details.header}</p>
        <div class="flex justify-center items-baseline my-8">
          <span className="text-xl font-bold">{details.pricePreffix}</span>
          <span class="mr-2 text-5xl font-extrabold">${details.price}</span>
          <span class=" text-gray-400">
            {details.lifetime ? " / lifetime" : ""}
          </span>
        </div>

        <ul role="list" class="mb-8 space-y-4 text-left">
          {details.infos.map((data, index) =>
            index % 2 === 0 ? (
              <li key={index} class="flex items-center space-x-3">
                <svg
                  class="flex-shrink-0 w-5 h-5text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>{data}</span>
              </li>
            ) : (
              <li class="flex items-center space-x-3">
                <svg
                  class="flex-shrink-0 w-5 h-5 text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>{data}</span>
              </li>
            )
          )}
        </ul>

        <a
          onClick={() => {
            handleUserLogging();
          }}
          className="buy-with-crypto cursor-pointer bg-secondary focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
          href={details.checkoutUrl}
        >
          Buy with Crypto
        </a>

        <EscrowPayment
          openWindow={openWindow}
          closeWindow={closeWindow}
          showWindow={showWindow}
          details={details}
        />

        <div>
          <ChooseModal
            isOpen={isModalOpen_package}
            onClose={closeModal_package}
          >
            <div className="bg-[#26303C] p-4 md:p-8">
              <div className="md:flex mb-4 md:mb-8 ">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <img src={Logo} alt="Logo" className="" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-white"
                    id="modal-headline"
                  >
                    {details?.title}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-200">{details?.header}</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center mb-4 border rounded-md shadow-md hover:shadow-md hover:shadow-slate-500 transition-all duration-150">
                <label
                  className="relative flex items-center p-4 mt-1 rounded-full cursor-pointer"
                  htmlFor="blockonomics"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-white checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                    id="blockonomics"
                    value="blockonomics"
                    checked={checkedValue === "blockonomics"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 011.414 0l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="blockonomics"
                >
                  <div className="flex items-center">
                    <p className="flex-1 text-white font-medium">
                      Pay ${priceAfterVoucher} with
                    </p>
                  </div>
                </label>
                <label
                  className="flex-1 font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="blockonomics"
                >
                  <div className="flex items-center">
                    <img src={Blockonomics} className="" alt="Blockonomics" />
                  </div>
                </label>
              </div>

              <div className="flex items-center mb-4 border rounded-md shadow-md hover:shadow-md hover:shadow-slate-500 transition-all duration-150">
                <label
                  className="relative flex items-center p-4 rounded-full cursor-pointer"
                  htmlFor="escrow"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-white checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                    id="escrow"
                    value="escrow"
                    checked={checkedValue === "escrow"}
                    onChange={handleCheckboxChange}
                  />
                  <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 011.414 0l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="escrow"
                >
                  <div className="flex items-center">
                    <p className="flex-1 text-white font-medium">
                      Pay ${priceAfterVoucher} with
                    </p>
                  </div>
                </label>
                <label
                  className="flex-1 font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="escrow"
                >
                  <div className="flex items-center">
                    <img src={Escrow} className="" alt="Escrow" />
                  </div>
                </label>
              </div>

              <div className="mt-5 sm:mt-6">
                <button
                  onClick={() => handleContinue()}
                  type="button"
                  className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm ${
                    loading ? "opacity-70 cursor-not-allowed" : ""
                  }`}
                >
                  {loading ? (
                    <div className="flex space-x-3 py-1">
                      <div className="w-3 h-3 bg-gray-600 rounded-full bubble"></div>
                      <div className="w-3 h-3 bg-gray-600 rounded-full bubble"></div>
                      <div className="w-3 h-3 bg-gray-600 rounded-full bubble"></div>
                    </div>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
              {/* 
              <div className="mt-4 text-white">
                Selected Value: {checkedValue}
              </div> */}
            </div>
          </ChooseModal>
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="bg-[#26303C] px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex justify-between sm:items-start">
                <div className="flex justify-between">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <img src={Logo} alt="Logo" className="" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-white"
                      id="modal-headline"
                    >
                      {details?.title}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-200">{details?.header}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-gray-900 bg-gray-200 px-4 py-1 rounded-md">
                    ${updatedPackagePrice}
                  </p>
                </div>
              </div>
              <div className="mt-3 md:mt-6 text-center tracking-wide font-mono space-y-1">
                <p className="">You can simply send the BTC to this address:</p>
                <p> {address}</p>
                <div className="flex gap-2 justify-center pt-2">
                  <p className="">Amount:</p>
                  <p>{btcRate} BTC</p>
                </div>
              </div>
              <div className="mt-3 md:mt-6">
                <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 150,
                    width: "100%",
                  }}
                  className="border-2"
                >
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={address}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};
