import React from "react";
import PropTypes from "prop-types";

const steps = ["Payment", "Escrow", "Confirmed"];

const Stepper = ({ currentStep }) => {
  return (
    <div className="flex flex-col items-center w-full mx-auto my-6">
      <div className="flex justify-center items-center w-full">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <div
              className={`rounded-full p-0.5 ${
                currentStep > index ? "border-2 border-[#3CB95D]/50" : ""
              }`}
            >
              <div
                className={`w-7 h-7 rounded-full flex items-center justify-center ${
                  currentStep > index
                    ? "bg-[#3CB95D] text-white"
                    : "bg-gray-400 text-white"
                }`}
              >
                {index + 1}
              </div>
            </div>

            {index < steps.length - 1 && (
              <div
                className={`md:w-[180px] h-1 ${
                  currentStep > index + 1 ? "bg-[#3CB95D]" : "bg-gray-400"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center w-full mt-2">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex flex-col items-center mx-6"
            style={{ width: "250px" }}
          >
            <span
              className={`text-center ${
                currentStep > index ? "text-gray-800" : "text-gray-400"
              }`}
            >
              {step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Stepper;
