import React, { useRef, useEffect } from "react";
import { useStep } from "../../contexts/stepContexts";
import "./draggable.css";

const DraggableWindow = ({ children, onClose }) => {
  const windowRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const element = windowRef.current;
    const headerElement = headerRef.current;
    const resizerElement = element.querySelector(".resizer");
    let isDragging = false;
    let isResizing = false;
    let initialMouseX, initialMouseY, initialWidth, initialHeight;

    const onMouseDown = (e) => {
      if (e.target === resizerElement) {
        isResizing = true;
        initialMouseX = e.clientX;
        initialMouseY = e.clientY;
        initialWidth = element.offsetWidth;
        initialHeight = element.offsetHeight;
        document.body.classList.add("no-select"); // Disable text selection
      } else if (e.target === headerElement || headerElement.contains(e.target)) {
        isDragging = true;
        initialMouseX = e.clientX - element.offsetLeft;
        initialMouseY = e.clientY - element.offsetTop;
        document.body.classList.add("no-select"); // Disable text selection
      }
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    const onMouseMove = (e) => {
      if (isDragging) {
        element.style.left = `${e.clientX - initialMouseX}px`;
        element.style.top = `${e.clientY - initialMouseY}px`;
      }
      if (isResizing) {
        const newWidth = initialWidth + (e.clientX - initialMouseX);
        const newHeight = initialHeight + (e.clientY - initialMouseY);
        element.style.width = `${newWidth}px`;
        element.style.height = `${newHeight}px`;
      }
    };

    const onMouseUp = () => {
      isDragging = false;
      isResizing = false;
      document.body.classList.remove("no-select"); // Re-enable text selection
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    headerElement.addEventListener("mousedown", onMouseDown);
    resizerElement.addEventListener("mousedown", onMouseDown);

    return () => {
      headerElement.removeEventListener("mousedown", onMouseDown);
      resizerElement.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const { url } = useStep();

  return (
    <div
      ref={windowRef}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[900px] h-[600px] z-50 bg-blue-50 border overflow-hidden border-gray-900 shadow-lg "
    >
      <div
        ref={headerRef}
        className="flex justify-between items-center gap-2  bg-gray-900 py-1.5 px-2"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-globe-americas"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
          </svg>
        </div>
        <div>
          <p className="text-xs text-gray-500">
            Transaction Details - Escrow.com
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <p className="w-3 h-3 bg-gray-700 rounded-full"></p>
          <p className="w-3 h-3 bg-gray-700 rounded-full"></p>
          <button
            onClick={onClose}
            className="text-black bg-blue-700 rounded-full w-3 h-3 flex justify-center items-center"
          >
            <span className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                fill="currentColor"
                className="fill-gray-50"
                class="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="flex items-center gap-2 bg-gray-800 py-1.5 px-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="currentColor"
          className="bi bi-exclamation-circle"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
        </svg>
        <div className="flex flex-1 overflow-hidden">
          <span className=" text-xs text-gray-400 h-4 flex text-start">
            https://www.escrow.com/transactions/process?q=
          </span>
          <span className=" text-xs text-gray-400 h-4 flex text-start">
            escrow+paymnet&oq=escrow+paymnet&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chromeie=UTF-8gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8
            escrow+paymnet&oq=escrow+paymnet&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chromeie=UTF-8gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8
            escrow+paymnet&oq=escrow+paymnet&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chromeie=UTF-8gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIJCAEQABgKGIAEMgkIAhAAGAoYgAQyCQgDEAAYChiABDIJCAQQABgKGIAEMgkIBRAAGAoYgAQyCQgGEAAYChiABDIJCAcQABgKGIAEMgkICBAAGAoYgAQyCQgJEAAYChiABNIBCDY5NDJqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8
          </span>
        </div>
        {/* <p className="text-xs text-gray-400 h-4 flex text-start overflow-hidden">
          https://www.escrow.com/transactions/64655683234/user123/status/in-progress?product=electronics&amount=5000&buyer=user456&seller=user789
        </p> */}
        {/* <p className="text-xs text-gray-400">{url}</p> */}
      </div>
      <div className="p-4 overflow-auto h-full">{children}</div>
      <div className="absolute w-4 h-4 bottom-0 right-0 cursor-se-resize resizer"></div>
    </div>
  );
};

export default DraggableWindow;
