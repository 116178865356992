import React from "react";

export default function Refund() {
  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold w-full ">
        Refund Policy
      </p>

      <div>
        <h4 className="text-xl font-bold my-[calc(2%)]">
          {" "}
          Thank you for choosing our software. We value your satisfaction and
          want to ensure that you are completely happy with your purchase.
          However, if you find that our software does not meet your needs, we
          offer a refund policy. Our refund policy is as follows:
        </h4>
        <p>
          1: You are entitled to a full refund within 7 days of purchase,
          provided that you have not share your license key to anyone.
        </p>
        <p>
          2: If you have activated your license key and the software is not
          functioning properly, we will work with you to resolve the issue. If
          we are unable to resolve the issue within a reasonable time frame, you
          may be eligible for a refund.
        </p>{" "}
        <p>
          3: Refunds will be issued in the same manner as the original payment.
          Please allow up to 3 business days for the refund to appear in your
          account.{" "}
        </p>
        <p>
          4: We reserve the right to refuse a refund if we believe that the
          software has been misused or if the refund request is made after the
          7-day period. Please note that if you purchased our software from a
          third-party vendor (unauthorized), refund will not available. If you
          have any questions or concerns about our refund policy, please contact
          us at{" "}
          <a href="https://t.me/hydraflasher" className="hover:underline">
            [https://t.me/hydraflasher]
          </a>
        </p>
      </div>
      <a
        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
        href={"/dashboard"}
      >
        Apply For Refund
      </a>
    </div>
  );
}
