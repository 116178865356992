import { Toaster } from "react-hot-toast";
import Background from "./shared/BackgroundImage";
import FloatingIcon from "./shared/floating.icon";
import Navbar from "./shared/Navbar";
import BlackFriday from "./blackfriday_meganav/main";

const App = () => {
  return (
    <div>
      <Background />
      <BlackFriday />
      <Navbar />
      <FloatingIcon />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default App;
