import React from "react";
import VideoCard from "../Videos-card/VideoCard";

const Videos = () => {
  const [VideosData, setVideosData] = React.useState([]);
  const getVideosData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch("/videos-data.json", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setVideosData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getVideosData();
  }, []);

  return (
    <div className="container mx-auto mt-5 px-5 flex flex-col items-center mb-5">
      <p className="text-3xl lg:w-1/2 text-center font-bold">Videos</p>
      <div className="grid grid-cols-1 justify-between gap-6 mt-16 flex-col lg:grid-cols-3">
        {VideosData.map((item, i) => {
          return <VideoCard key={i} src={item.src} title={item.title} />;
        })}
      </div>
    </div>
  );
};

export default Videos;
