import React, { useEffect } from "react";

const DisableInspect = () => {
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (
        (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
        (e.ctrlKey && e.shiftKey && e.key === "C") || // Ctrl+Shift+C
        (e.ctrlKey && e.shiftKey && e.key === "J") || // Ctrl+Shift+J
        (e.ctrlKey && e.key === "U") || // Ctrl+U
        e.key === "F12" // F12
      ) {
        e.preventDefault();
        e.returnValue = false;
      }

      // Prevent F12 for other browsers that may use different keycodes
      if (e.keyCode === 123) {
        // F12 key code
        e.preventDefault();
        e.returnValue = false;
      }

      // Prevent other variations
      if (
        (e.ctrlKey || e.metaKey) &&
        e.shiftKey &&
        (e.keyCode === 73 || e.keyCode === 67 || e.keyCode === 74)
      ) {
        e.preventDefault();
        e.returnValue = false;
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return null;
};

export default DisableInspect;
