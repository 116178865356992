import React, { createContext, useState, useContext } from "react";

const StepContext = createContext();

export const StepProvider = ({ children }) => {
  // const [step, setStep] = useState(localStorage.getItem("step") || "login");
  const [step, setStep] = useState("login");
  const [url, setUrl] = useState(
    "https://www.escrow.com/transactions/.................../..................../.........................................../transaction/11936769"
  );
  const [selectedMethod, setSelectedMethod] = useState("");
  const [priceAfterVoucher, setPriceAfterVoucher] = useState(null);
  const [userEmail, setUserEmail] = useState("");

  const changeStep = (newStep) => {
    // localStorage.setItem("step", newStep);
    setStep(newStep);
  };

  return (
    <StepContext.Provider
      value={{
        step,
        changeStep,
        url,
        setUrl,
        selectedMethod,
        setSelectedMethod,
        priceAfterVoucher,
        setPriceAfterVoucher,
        userEmail,
        setUserEmail
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => useContext(StepContext);
