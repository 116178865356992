import { useState } from "react";
import rdpImage from "../../assets/images/services/rdp.png";
import standardImage from "../../assets/images/services/standard.png";
import premiumImage from "../../assets/images/services/premium.png";

export const Services = () => {
  const [selected, setSelected] = useState("Hydra Flasher Standard");

  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold w-full">
        Our Services
      </p>
      <div className="flex gap-6 mt-16 w-full bg-gray-800 py-5 px-3 flex-col lg:flex-row">
        <div className="lg:w-1/4 w-full">
          <ul className="flex flex-col">
            <li
              onClick={() => setSelected("Hydra Flasher Standard")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Flasher Standard
            </li>
            <li
              onClick={() => setSelected("Hydra Flasher Premium")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Flasher Premium
            </li>
            <li
              onClick={() => setSelected("Hydra Script (API)")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Script (API)
            </li>
            <li
              onClick={() => setSelected("Hydra RDP")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra RDP
            </li>
          </ul>
        </div>
        <div className="lg:w-3/4 flex flex-col items-center w-full">
          <p className="text-xl font-bold">{selected}</p>
          <div className=" flex justify-between mt-5 px-3 w-full lg:flex-row flex-col">
            <div className="lg:w-1/3 flex justify-center items-center w-full">
              {selected === "Hydra Flasher Standard" && (
                <img src={standardImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra Flasher Premium" && (
                <img src={premiumImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra RDP" && (
                <img src={rdpImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra Script (API)" && (
                <img src={premiumImage} alt="" className="max-w-[200px]" />
              )}
            </div>
            <div className="lg:w-3/4 w-full">
              <p>
                {selected === "Hydra Flasher Standard" && (
                  <div>
                    <p>1. Our Flasher Works on Almost All Wallet. </p>
                    <p>
                      2. You Can Transfer Flash Funds To Any Wallet Like
                      Binance, Coinbase, Blockchain, Atomic etc.
                    </p>
                    <p>3. No Setup, or Hidden Fees.</p>
                    <p>
                      4. HydraFlasher Operators Will Not Ask For Any Personal
                      Data Ever.
                    </p>
                    <p>5. Flash Funds Stays For Minimum 21 Days.</p>
                    <p>6. If Face any Problem, Feel Free to Contact Us.</p>
                    <p>
                      7. Our Customer Service Operator Will Always Here To Help
                      You.
                      <p>
                        8. Telegram:{" "}
                        <a href="https://t.me/hydraflasher">
                          {" "}
                          https://t.me/hydraflasher
                        </a>
                      </p>{" "}
                    </p>
                  </div>
                )}
                {selected === "Hydra Flasher Premium" && (
                  <div>
                    <p>1. Our Flasher Works on Almost All Wallet. </p>
                    <p>
                      2. You Can Transfer Flash Funds To Any Wallet Like
                      Binance, Coinbase, Blockchain, Atomic etc.
                    </p>
                    <p>3. No Setup, or Hidden Fees.</p>
                    <p>
                      4. HydraFlasher Operators Will Not Ask For Any Personal
                      Data Ever.
                    </p>
                    <p>5. Flash Funds Stays For Minimum 21 Days.</p>
                    <p>6. If Face any Problem, Feel Free to Contact Us.</p>
                    <p>
                      7. Our Customer Service Operator Will Always Here To Help
                      You.
                      <p>
                        8. Telegram:{" "}
                        <a
                          className="font-bold hover:underline"
                          href="https://t.me/hydraflasher"
                        >
                          {" "}
                          https://t.me/hydraflasher
                        </a>
                      </p>{" "}
                    </p>
                  </div>
                )}
                {selected === "Hydra RDP" &&
                  `Remote Desktop Protocol (RDP) Is a Secure Network communications Protocol Developed By Microsoft. 
									 It Enables Network Administrators To Remotely Diagnose Problems That Individual Users Encounter And Gives Users Remote Access To Their Physical Work Desktop Computers.
									 It Can Be Controle By Using Any Physical Computer Or Any Android Device `}

                {selected === "Hydra Script (API)" && (
                  <div>
                    <p>
                      1. Setup (For Debian Linux and Windows) Command-line
                      Interface
                    </p>
                    <p>
                      2. Daily Flash Limit: "No Limitation" Trade and Transfer
                    </p>
                    <p>
                      {" "}
                      3. Facility. Free Setup For First time Free updates:
                      Subscription Time Only.
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
