import React from "react";
import { AiOutlineStar, AiTwotoneStar } from "react-icons/ai";

export const Review = ({ review, username, rating, profession }) => {
  const [rate, setRate] = React.useState([]);
  const genrate_rating = (rating) => {
    let rate_data = [];
    rating = parseInt(rating);
    let total_rate = 5;
    for (let i = 0; i < rating; i++) {
      rate_data.push(1);
    }
    for (let i = 0; i < total_rate - rating; i++) {
      rate_data.push(0);
    }
    setRate(rate_data);
  };
  React.useEffect(() => {
    genrate_rating(rating);
  }, []);
  return (
    <div className="flex flex-col p-8 bg-accent/70 backdrop-blur-md rounded-2xl">
      <div className="w-full">
        <p>{review}</p>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex items-center">
          <div className="flex flex-col">
            <p>
              <span className="font-mono font-bold text-2xl">{username}</span>
            </p>
            <p>
              <span className="">{profession}</span>
            </p>
          </div>
        </div>
        <div className="justify-between flex items-center">
          {rate.map((item, i) => {
            if (item == 1) {
              return <AiTwotoneStar key={i} />;
            } else {
              return <AiOutlineStar key={1} />;
            }
          })}
        </div>
      </div>
    </div>
  );
};
