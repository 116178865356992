import { Route, Routes } from "react-router-dom";
import Home from "../components/pages/Home";
import Login from "../components/pages/Login";
import Dashboard from "../components/pages/Dashboard";
import Register from "../components/pages/Register";
import { Services } from "../components/pages/Services";
import { Shop } from "../components/pages/Shop";
import Reviews from "../components/pages/Reviews";
import Videos from "../components/pages/Videos";
import Download from "../components/pages/Download";
import Refund from "../components/pages/Refund";
import News from "../components/pages/News";
import Report from "../components/pages/Report";
import UpdateNotice from "../components/pages/UpdateNotice";
import ErrorPage from "../components/pages/ErrorPage";
import LiveChat from "../components/pages/LiveChat";
import Transactions from "../components/pages/Transactions";

const ManageRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/services" element={<Services />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/download" element={<Download />} />
      <Route path="/refund-policy" element={<Refund />} />
      <Route path="/report-scam" element={<Report />} />

      <Route path="/notice-update" element={<UpdateNotice />} />
      <Route path="/news" element={<News />} />
      <Route path="/Coming_Soon" element={<LiveChat />} />
      <Route path="/all-transactions" element={<Transactions />} />

      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default ManageRoute;
