import React from "react";
import ReportDesc from "../../ReportScam/ReportDesc";

export default function NewsCard({ news }) {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <div key={news._id}>
      <div
        key={news._id}
        class="py-8 flex flex-wrap md:flex-nowrap  px-10 border-2 rounded backdrop-blur-3xl"
      >
        <div class="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex  flex-col">
          <span class="font-semibold title-font text-gray-200  w-40 text-center py-1 rounded-full bg-gray-700 ">
            {news?.category}
          </span>

          <img
            className="p-2 rounded-3xl w-[300px] sm:w-[450px]"
            src={news?.image}
            alt={news?.title}
          />
        </div>
        <div class="md:flex-grow">
          <h2 class="text-2xl font-medium text-gray-400 title-font mb-2">
            {news?.title}{" "}
            <span
              title="Posted On"
              class="mt-2 text-gray-400 text-sm  w-40 text-center hover:underline ml-[clac(10%)] "
            >
              {new Date(news?.date).toLocaleDateString("en-GB")}
            </span>
          </h2>
          <p class="leading-relaxed">
            <ReportDesc description={news?.desc} />
          </p>
          <a
            class="text-indigo-500 inline-flex items-center mt-4 font-bold"
            href={news?.link}
          >
            {news?.link}
            <svg
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
