import React, { useEffect, useRef, useState } from "react";
import { useStep } from "../../../contexts/stepContexts";
import PackageDescription from "../PackageDescription";
import Stepper from "./Stepper";
import axios from "axios";
import Logo from "../../../../src/assets/images/logo192.png";
import { EscrowBlockonomics, UpdateInvoiceUrl } from "../../../utils/Routes";
import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import CopyComponent from "../../Dashboard/CopyComponent";

const MethodConfirmation = ({ details }) => {
  const { selectedMethod, setUrl, priceAfterVoucher, changeStep, userEmail } =
    useStep();
  const [address, setAddress] = useState(null);
  const [btcRate, setBtcRate] = useState(0);
  const [updatedPackagePrice, setUpdatedPackagePrice] = useState(0);

  // Ref to track if handleGenerateAddress has been called
  const hasGeneratedAddress = useRef(false);

  useEffect(() => {
    setUrl("http://127.0.0.1:5500/escrow/payment-method");
  }, [setUrl]);

  useEffect(() => {
    if (!hasGeneratedAddress.current) {
      handleGenerateAddress();
      hasGeneratedAddress.current = true;
    }
  }, []); // Empty dependency array to call it once on mount

  useEffect(() => {
    if (address) {
      const socket = io(`https://api.hydraflasher.com/payment/${address}`);

      // client-side
      socket.on("connect", () => {
        console.log("Connection Established");
      });

      socket.on("message", async (txs) => {
        console.log("here is the new transaction", txs);

        if (txs) {
          let status;
          if (txs.status == -1) {
            status = "PAYMENT_ERROR";
          } else if (txs.status == 0) {
            status = "Unconfirmed";
          } else if (txs.status == 1) {
            status = "Partially Confirmed";
          } else if (txs.status == 2) {
            status = "Confirmed";
          }

          try {
            await axios.patch(UpdateInvoiceUrl + `/${address}`, {
              trx_id: txs.txid,
              status,
              paid_amount: txs.value,
              address: address,
            });

            changeStep("success");
          } catch (error) {
            console.error(error);
          }
        }
      });

      socket.on("disconnect", () => {
        console.log(socket.id);
      });
    }
  }, [address]);

  const handleGenerateAddress = async () => {
    let user = localStorage.getItem("user");

    if (user != null) {
      let userInfo = JSON.parse(user);

      // Remove commas from priceAfterVoucher and convert to number
      const sanitizedPrice = parseFloat(priceAfterVoucher.replace(/,/g, ""));

      try {
        const response = await axios.post(EscrowBlockonomics, {
          price: sanitizedPrice + 32,
          product_name: details?.title,
          email: userInfo.email,
        });

        setAddress(response?.data?.newAddressData?.address);
        setBtcRate(response?.data?.price_in_btc);
        setUpdatedPackagePrice(response?.data?.price);
      } catch (error) {
        console.error(error);
        // Handle payment errors
      }
    }
  };

  return (
    <div className="pb-10 pt-8 mb-10 overflow-hidden bg-blue-50">
      <div className="flex justify-between">
        <div className="flex justify-start">
          <svg
            version="1.1"
            fill="#fff"
            class="logo"
            // width="473.187"
            height="35.5"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 336.2 612 119.4"
            enable-background="new 0 336.2 612 119.4"
            className=""
          >
            <g class="logo-shield">
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M56.6,455.6h-1c-0.5-0.5-56.9-15.5-55.4-99.3v-1.2l7.7-11l1.5-0.2
          c1-0.2,47.2-7.5,47.2-7.5s46.4,7.2,47.4,7.5l1.2,0.2l7.7,11v1.2c1,43.4-14,68.1-26.7,81.1C72.4,451.6,57.9,455.6,56.6,455.6z
           M8,357.8c-1,71.4,41.7,87.6,48.7,89.8c7.2-2.2,49.6-18.5,48.7-90.1l-4.7-6.7c-5.2-1.2-25.7-6.2-43.9-6.7
          c-18.2,0.5-38.4,5.5-43.9,6.7L8,357.8z"
              ></path>
              <rect
                fill="#012B49"
                x="37.4"
                y="388.5"
                class="logo-text--primary"
                width="41.9"
                height="8.2"
              ></rect>
              <rect
                fill="#012B49"
                x="37.4"
                y="409.2"
                class="logo-text--primary"
                width="41.9"
                height="8.2"
              ></rect>
              <rect
                fill="#012B49"
                id="XMLID_39_"
                x="37.4"
                y="367.8"
                class="logo-text--primary"
                width="41.9"
                height="8.2"
              ></rect>
              <path
                fill="#42C21C"
                class="logo-text--secondary"
                d="M37.2,400c-4,0-7.5-3.5-7.5-7.7c0-4,3.2-7.7,7.5-7.7h19.7v-5.7h-20
        c-7.5,0-13.2,6-13.2,13.5l0,0c0,7.5,6,13.5,13.2,13.5h20V400H37.2z"
              ></path>
            </g>
            <g class="logo-text">
              <polygon
                fill="#012B49"
                class="logo-text--primary"
                points="401.2,417.5 391.7,379.8 382.2,417.5 372,417.5 359.3,366.6 368.7,366.6
      377.7,402.5 386.7,366.6 396.9,366.6 405.9,402.5 414.9,366.6 424.4,366.6 411.7,417.5       "
              ></polygon>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M299.6,417.7l-12.5-16.5h-7.2v16.5h-9v-49.6h21c6.2,0,14,5.5,14,16
      c0,11.7-8.7,15.2-9.2,15.5l13.2,18.5h-10.2V417.7z M291.2,392.5c2.7,0,6-3,6-8c0-4.7-2.7-7.5-6.2-7.5h-11v15.5H291.2z"
              ></path>
              <polygon
                fill="#012B49"
                class="logo-text--primary"
                points="145.5,417.7 145.5,368.1 177.9,368.1 177.9,375 153.4,375 153.4,389.3
      174.6,389.3 174.6,396.2 153.4,396.2 153.4,410.5 177.9,410.5 177.9,417.7       "
              ></polygon>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M334.8,418.7c-11.5,0-19.2-6.5-19.2-16V382c0-9.2,7.5-15,19.2-15c11.5,0,19.2,6.5,19.2,16
      v20C354,412.2,346,418.7,334.8,418.7z M334.8,374.8c-5.2,0-10.7,2-10.7,8v19c0,5.2,4.5,9,10.7,9c6.2,0,10.7-3.7,10.7-9v-18
      C345.3,378.5,340.8,374.8,334.8,374.8z"
              ></path>
              <path
                fill="#42C21C"
                class="logo-text--secondary"
                d="M506.5,418.7c-11,0-18.7-6.5-18.7-16v-21c0-9.2,7.5-15,18.7-15c11,0,18.7,6.5,18.7,16v20
      C525.2,412.2,517.4,418.7,506.5,418.7z M506.5,374.8c-5,0-10.5,2-10.5,8v19c0,5.2,4.2,9,10.5,9c6,0,10.5-3.7,10.5-9v-18
      C516.7,378.5,512.5,374.8,506.5,374.8z"
              ></path>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M246.2,418.7c-4.2,0-8.7-0.5-12.5-4c-4-4-5.7-10.5-5.5-22.2c-0.2-11.5,1.5-18.2,5.5-22
      c3.5-3.5,8.2-4,12.5-4c2,0,4,0,6.2,0.2c2.7,0.2,8,1.2,8.2,1.2l0.7,0.2v7v1.5c0,0-0.7,0-1.7-0.2c-1.7-0.2-5.7-1-7.7-1
      c-2.2-0.2-4.2-0.2-5.7-0.2c-3.2,0-5.2,0.5-6.5,1.5c-2,2-3,7.2-3,16v0.2c-0.2,8.5,0.7,14,3,16c1.2,1,3,1.5,6.5,1.5
      c1.7,0,3.7,0,5.7-0.2c2.5-0.2,8.5-1.2,8.5-1.2l1-0.2v8.5l-0.7,0.2c-0.2,0-5.7,1-8.2,1.2C250.2,418.5,248.2,418.7,246.2,418.7z"
              ></path>
              <path
                fill="#42C21C"
                class="logo-text--secondary"
                d="M463.1,418.7c-4.2,0-8.7-0.5-12.5-4c-4-4-5.7-10.5-5.5-22.2c-0.2-11.5,1.5-18.2,5.5-22
      c3.5-3.5,8.2-4,12.5-4c2,0,4.2,0,6.5,0.2c2.7,0.2,7.7,1.2,8,1.2l0.7,0.2v8.5c0,0-0.2,0-0.7,0c-1.7-0.2-7-1.2-8.7-1.2
      c-2.2-0.2-4.2-0.2-5.7-0.2c-3.2,0-5.2,0.5-6.5,1.5c-2,2-3,7.2-3,16v0.2c-0.2,8.5,0.7,14,3,16c1.2,1,3,1.5,6.5,1.5
      c1.7,0,3.7,0,5.7-0.2c2.5-0.2,8.5-1.2,8.5-1.2l1-0.2v8.5l-0.7,0.2c-0.2,0-5.7,1-8.2,1.2C467.3,418.7,465.1,418.7,463.1,418.7z"
              ></path>
              <polygon
                fill="#42C21C"
                class="logo-text--secondary"
                points="574.1,418.7 572.8,388 563.3,412 553.9,412 544.1,388 543.1,418.7 534.2,418.7
      535.9,366.8 544.6,366.8 558.6,401.5 572.6,366.8 581.1,366.8 583.1,418.7       "
              ></polygon>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M203.1,419c-4.7,0-9.5-1.2-14.2-3.5l-0.7-0.2l3.5-7.5l0.7,0.2c3.7,1.7,7.5,2.7,10.7,2.7
      c8.5,0,9-4.7,9-6c0-2.2-0.2-5.2-3.5-6.5c-0.7-0.2-2.5-1-4.5-1.5c-5.5-1-15.7-3.2-15.7-16c0-4.2,1.5-7.7,4.2-10.2
      c3-2.5,7-3.7,12.7-3.7c3.2,0,6.7,0.5,9.5,0.7c1.2,0.2,4.5,0.5,4.5,0.5l-0.5,8.2c0,0-3.2-0.5-4.7-0.7c-2.5-0.2-5.7-0.5-8.7-0.7
      c-7.7-0.2-8.5,3.7-8.7,5.7c-0.2,1.7,0,5.7,9,7.7c3,0.7,5.7,1.5,8.2,3c6.2,3.5,7.2,9.7,6,16.5c-0.5,3.2-2.5,6.2-6,8.2
      C211.3,418,207.3,419,203.1,419z"
              ></path>
              <circle
                fill="#42C21C"
                class="logo-text--secondary"
                cx="431.1"
                cy="413"
                r="5.7"
              ></circle>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M600.8,411.5h-2.5v7.2h-1.5v-7.2h-2.5v-1.2h6.7v1.2H600.8z"
              ></path>
              <path
                fill="#012B49"
                class="logo-text--primary"
                d="M612,410.2v8.5h-1.5v-5.5c0-0.2,0-0.5,0-0.7l-2.5,5c0,0.2-0.2,0.2-0.5,0.2h-0.2
      c-0.2,0-0.5,0-0.5-0.2l-2.7-5c0,0.2,0,0.2,0,0.5s0,0.2,0,0.2v5.5h-1.5v-8.5h1.2h0.2c0,0,0,0,0.2,0l0,0c0,0,0,0,0,0.2l2.5,4.7
      c0,0.2,0.2,0.2,0.2,0.5c0,0.2,0,0.2,0.2,0.5c0-0.2,0-0.2,0.2-0.5c0-0.2,0-0.2,0.2-0.5l2.5-4.7c0,0,0,0,0-0.2l0,0c0,0,0,0,0.2,0
      c0,0,0,0,0.2,0C610.8,410.2,612,410.2,612,410.2z"
              ></path>
            </g>
          </svg>{" "}
        </div>
        <p className="text-gray-900 font-medium">Logged in as {userEmail}</p>
      </div>

      <div className="bg-white mt-5 md:mt-10 shadow-2xl rounded-md py-3 md:py-6 max-w-xl mx-auto">
        <PackageDescription details={details} fee={true} />

        <hr className="my-14" />

        <Stepper currentStep={2} />

        <div className="mx-auto lg:max-w-[800px] p-6">
          <h1 className="font-bold text-center mb-6 text-gray-700 flex">
            Paying through {selectedMethod}
          </h1>

          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mb-3 md:mb-6">
              <div
                style={{
                  height: "auto",
                  margin: "0 auto",
                  maxWidth: 150,
                  width: "100%",
                }}
                className=""
              >
                {address && (
                  <div
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  >
                    <QRCode
                      className="border-2 border-[#3CB95D] p-1 md:p-2"
                      size={216}
                      value={address}
                      fgColor="#3CB95D" // Green color
                      viewBox="0 0 256 256"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col items-center mt-2 gap-2 md:gap-4 md:mt-4 text-center tracking-wide font-mono space-y-1 text-gray-800">
              <CopyComponent text={address} />

              <div className="flex">
                Amount(BTC)-
                <CopyComponent text={btcRate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MethodConfirmation;
